export const GET_HOUR_MEMBER_REQUEST = 'GET_HOUR_MEMBER_REQUEST'
export const GET_HOUR_MEMBER_SUCCESS = 'GET_HOUR_MEMBER_SUCCESS'
export const GET_HOUR_MEMBER_FAIL = 'GET_HOUR_MEMBER_FAIL'
export const GET_HOUR_MEMBER_REMOVE = 'GET_HOUR_MEMBER_REMOVE'

export const CREATE_APPOINTMENT_REQUEST = 'CREATE_APPOINTMENT_REQUEST'
export const CREATE_APPOINTMENT_SUCCESS = 'CREATE_APPOINTMENT_SUCCESS'
export const CREATE_APPOINTMENT_FAIL = 'CREATE_APPOINTMENT_FAIL'
export const CREATE_APPOINTMENT_REMOVE = 'CREATE_APPOINTMENT_REMOVE'

export const CREATE_QUESTION_CASDI_REQUEST = 'CREATE_QUESTION_CASDI_REQUEST'
export const CREATE_QUESTION_CASDI_SUCCESS = 'CREATE_QUESTION_CASDI_SUCCESS'
export const CREATE_QUESTION_CASDI_FAIL = 'CREATE_QUESTION_CASDI_FAIL'
export const CREATE_QUESTION_CASDI_REMOVE = 'CREATE_QUESTION_CASDI_REMOVE'

export const GET_QUESTION_CASDI_REQUEST = 'GET_QUESTION_CASDI_REQUEST'
export const GET_QUESTION_CASDI_SUCCESS = 'GET_QUESTION_CASDI_SUCCESS'
export const GET_QUESTION_CASDI_FAIL = 'GET_QUESTION_CASDI_FAIL'
export const GET_QUESTION_CASDI_REMOVE = 'GET_QUESTION_CASDI_REMOVE'

export const DELETE_QUESTION_CASDI_REQUEST = 'DELETE_QUESTION_CASDI_REQUEST'
export const DELETE_QUESTION_CASDI_SUCCESS = 'DELETE_QUESTION_CASDI_SUCCESS'
export const DELETE_QUESTION_CASDI_FAIL = 'DELETE_QUESTION_CASDI_FAIL'

export const CREATE_ANSWER_CASDI_REQUEST = 'CREATE_ANSWER_CASDI_REQUEST'
export const CREATE_ANSWER_CASDI_SUCCESS = 'CREATE_ANSWER_CASDI_SUCCESS'
export const CREATE_ANSWER_CASDI_FAIL = 'CREATE_ANSWER_CASDI_FAIL'
export const CREATE_ANSWER_CASDI_REMOVE = 'CREATE_ANSWER_CASDI_REMOVE'

// CASDI ARTICLES
export const CREATE_CASDI_ARTICLE_REQUEST = 'CREATE_CASDI_ARTICLE_REQUEST'
export const CREATE_CASDI_ARTICLE_SUCCESS = 'CREATE_CASDI_ARTICLE_SUCCESS'
export const CREATE_CASDI_ARTICLE_FAIL = 'CREATE_CASDI_ARTICLE_FAIL'
export const CREATE_CASDI_ARTICLE_REMOVE = 'CREATE_CASDI_ARTICLE_REMOVE'

export const LIST_CASDI_ARTICLE_REQUEST = 'LIST_CASDI_ARTICLE_REQUEST'
export const LIST_CASDI_ARTICLE_SUCCESS = 'LIST_CASDI_ARTICLE_SUCCESS'
export const LIST_CASDI_ARTICLE_FAIL = 'LIST_CASDI_ARTICLE_FAIL'

export const CASDI_ARTICLE_BY_ID_REQUEST = 'CASDI_ARTICLE_BY_ID_REQUEST'
export const CASDI_ARTICLE_BY_ID_SUCCESS = 'CASDI_ARTICLE_BY_ID_SUCCESS'
export const CASDI_ARTICLE_BY_ID_FAIL = 'CASDI_ARTICLE_BY_ID_FAIL'

export const UPDATE_CASDI_ARTICLE_REQUEST = 'UPDATE_CASDI_ARTICLE_REQUEST'
export const UPDATE_CASDI_ARTICLE_SUCCESS = 'UPDATE_CASDI_ARTICLE_SUCCESS'
export const UPDATE_CASDI_ARTICLE_FAIL = 'UPDATE_CASDI_ARTICLE_FAIL'

export const DELETE_CASDI_ARTICLE_REQUEST = 'DELETE_CASDI_ARTICLE_REQUEST'
export const DELETE_CASDI_ARTICLE_SUCCESS = 'DELETE_CASDI_ARTICLE_SUCCESS'
export const DELETE_CASDI_ARTICLE_FAIL = 'DELETE_CASDI_ARTICLE_FAIL'
