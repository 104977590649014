export const memberSocialMedia = {
    isti: {
        instagram: 'https://www.instagram.com/istianindya/',
        email: 'istianindya@gmail.com'
    },
    santi: {
        instagram: 'https://www.instagram.com/nurendahsanti/',
        email: 'nurendahsanti17@gmail.com'
    },
    elga: {
        instagram: 'https://www.instagram.com/elgaridlo/',
        email: 'elgaridlosinatriya@gmail.com',
    },
    habil: {
        instagram: 'https://www.instagram.com/isfanazha/',
        email: 'isfanazhabil@gmail.com'
    },
    tia: {
        instagram: 'https://www.instagram.com/tiahzet/',
        email: 'tiawida.e.hz@gmail.com'
    },
    sarah: {
        instagram: 'https://www.instagram.com/bilqisrh/',
        email: ''
    },
    missyarah: {
        instagram: 'https://www.instagram.com/missyarah14/',
        email: 'missyarah1@gmail.com'
    },
    nandia: {
        instagram: 'https://www.instagram.com/nandia.s/',
        email: 'septiyorininandia@gmail.com'
    },
    novika: {
        instagram: 'https://www.instagram.com/novika911/',
        email: 'novikapurnamasari@gmail.com'
    }
}