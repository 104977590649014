export const USER_LOGIN_REQUEST = 'USER_LOGIN_REQUEST'
export const USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS'
export const USER_LOGIN_FAIL = 'USER_LOGIN_FAIL'
export const USER_LOGOUT = 'USER_LOGOUT'

export const USER_PROFILE_REQUEST = 'USER_PROFILE_REQUEST'
export const USER_PROFILE_SUCCESS = 'USER_PROFILE_SUCCESS'
export const USER_PROFILE_FAIL = 'USER_PROFILE_FAIL'

export const ADD_USER_REQUEST = 'ADD_USER_REQUEST'
export const ADD_USER_SUCCESS = 'ADD_USER_SUCCESS'
export const ADD_USER_FAIL = 'ADD_USER_FAIL'
export const ADD_USER_REMOVE = 'ADD_USER_REMOVE'

export const UPDATE_PASSWORD_ID_REQUEST = 'UPDATE_PASSWORD_ID_REQUEST'
export const UPDATE_PASSWORD_ID_SUCCESS = 'UPDATE_PASSWORD_ID_SUCCESS'
export const UPDATE_PASSWORD_ID_FAIL = 'UPDATE_PASSWORD_ID_FAIL'

export const AUTH_BY_ID_REQUEST = 'AUTH_BY_ID_REQUEST'
export const AUTH_BY_ID_SUCCESS = 'AUTH_BY_ID_SUCCESS'
export const AUTH_BY_ID_FAIL = 'AUTH_BY_ID_FAIL'