import React from 'react';

const CasdiResearch = () => {
  return (
    <>
      <section className="wrapper bg-light angled upper-end lower-end">
        <div className="container py-14 py-md-16">
          <div className="row gx-lg-8 gx-xl-12 gy-10 mb-14 mb-md-17 align-items-center">
            <div className="col-xl-6 mx-auto">
              <h1 className="display-1 mb-4">Riset CASDI</h1>
            </div>
            <div className="col-lg-12">
              <img
                src="/assets/img/icons/megaphone.svg"
                className="svg-inject icon-svg icon-svg-md mb-4"
                alt=""
              />
              <p className="mb-6" style={{ textAlign: 'justify' }}>
                &emsp;&ensp;Riset CASDI pertama dilakukan oleh Isti Anindya,
                S.Si., M.Sc saat sedang menyelesaikan Program Doktoral Ilmu
                Biomedik di Fakultas Kedokteran Universitas Indonesia. Founder
                Peduli ASD ini dibimbing oleh Prof. dr. Amin Soebandrio, Sp.MK
                (K), PhD, Prof. Dr. dr. Rini Sekartini, Sp.A (K), dan Dr. Ibnu
                Agus Ariyanto, S.Si., M.Biomed. Pada tahun 2023, CASDI berhasil
                mendapatkan Pendanaan Riset dan Inovasi untuk Indonesia Maju
                (RIIM) dari BRIN dan melakukan riset pertamanya yang berjudul{' '}
                <strong>
                  “Pengembangan Aplikasi Skrining Penanganan ASD (<i>Autism
                  Spectrum Disorder</i>) : Studi Biomarker pada Anak ASD Seropositif
                  CMV (<i>Cytomegalovirus</i>) dan Kaitannya dengan Kondisi Autistik.”
                </strong>
                Penelitian pertama ini berfokus melihat profil sitokin IL-1B
                (Interleukin-1 Beta) dan IL-6 (Interleukin-6) sebagai biomarker
                imunologi. Beberapa penelitian telah menunjukkan adanya kaitan
                antara profil sitokin IL-1B dan IL-6 dengan perilaku autistik.
                Sitokin-sitokin ini merupakan molekul yang terlibat dalam sistem
                kekebalan tubuh dan berperan dalam merespons peradangan dan
                infeksi. Beberapa studi menemukan bahwa anak-anak dengan autisme
                memiliki kadar IL-1B dan IL-6 yang lebih tinggi dalam darah atau
                cairan serebrospinal dibandingkan dengan anak-anak tanpa
                autisme. Peningkatan kadar sitokin-sitokin ini menunjukkan
                adanya aktivasi sistem kekebalan tubuh atau peradangan pada
                individu dengan autisme.
              </p>
              <p className="mb-6" style={{ textAlign: 'justify' }}>
                &emsp;&ensp;Peradangan yang terjadi dapat mempengaruhi
                perkembangan dan fungsi otak, serta memengaruhi perilaku.
                Beberapa penelitian telah menunjukkan bahwa peningkatan kadar
                IL-1B dan IL-6 dapat berhubungan dengan gejala-gejala autisme,
                seperti gangguan komunikasi sosial, gangguan sensorik, serta
                perilaku repetitif dan terbatas. Peningkatan sitokin-sitokin ini
                juga telah dikaitkan dengan gangguan tidur, kecemasan, dan
                masalah perilaku lainnya yang sering terjadi pada individu
                dengan autisme. Namun, penting untuk diingat bahwa profil
                sitokin IL-1B dan IL-6 tidak selalu sama pada setiap individu
                dengan autisme. Setiap individu dapat memiliki variasi dalam
                respons imun mereka terhadap peradangan atau infeksi. Selain
                itu, penelitian lebih lanjut diperlukan untuk memahami mekanisme
                yang lebih tepat tentang bagaimana profil sitokin ini
                berhubungan dengan perilaku autistik. Meskipun belum ada
                penawaran pengobatan spesifik berdasarkan profil sitokin ini,
                pemahaman lebih lanjut tentang peran sitokin dalam autisme dapat
                membantu dalam pengembangan strategi pengobatan yang lebih
                efektif di masa depan.
              </p>
              <p className="mb-6" style={{ textAlign: 'justify' }}>
                &emsp;&ensp;Selain itu, penelitian ini juga melakukan skrining
                paparan infeksi Cytomegalovirus (CMV) pada anak ASD yang
                ternyata dapat mempengaruhi profil sitokin IL-1B dan IL-6 pada
                tubuh anak. CMV adalah virus yang dapat menginfeksi janin selama
                masa kehamilan jika ibu hamil terinfeksi atau pada masa
                anak-anak setelah lahir. Infeksi CMV dapat merangsang sistem
                kekebalan tubuh anak untuk memproduksi sitokin IL-1B dan IL-6
                sebagai respons terhadap virus. Sitokin-sitokin ini adalah
                molekul yang berperan dalam merespons peradangan dan infeksi,
                serta mengatur komunikasi antara sel-sel kekebalan tubuh. CMV
                dapat memicu produksi IL-1B dan IL-6 dengan beberapa mekanisme.
                CMV menginfeksi sel-sel tubuh, termasuk sel-sel imun seperti
                makrofag dan sel dendritik. Infeksi ini dapat merangsang sel-sel
                ini untuk melepaskan sitokin-sitokin pro-inflamasi, termasuk
                IL-1B dan IL-6. Selain itu, CMV juga dapat mempengaruhi jalur
                sinyal imun dan merangsang sel-sel imun lainnya untuk
                memproduksi sitokin-sitokin ini.
              </p>
              <p className="mb-6" style={{ textAlign: 'justify' }}>
                &emsp;&ensp;Peningkatan produksi IL-1B dan IL-6 akibat infeksi
                CMV dapat menyebabkan peradangan sistemik atau lokal. Peradangan
                ini kemudian dapat mempengaruhi fungsi neurologis dan
                berkontribusi pada risiko perkembangan gangguan neurologis,
                termasuk autisme. Tingginya kadar IL-1B dan IL-6 juga telah
                dikaitkan dengan gejala autisme, seperti gangguan komunikasi
                sosial, gangguan sensorik, dan perilaku repetitif. Namun,
                penting untuk diingat bahwa respons imun terhadap infeksi CMV
                dapat bervariasi antar individu. Beberapa anak mungkin
                menghasilkan respons imun yang lebih kuat dan menghasilkan kadar
                IL-1B dan IL-6 yang lebih tinggi, sementara yang lain mungkin
                tidak menunjukkan respons yang sama. Faktor-faktor genetik dan
                lingkungan juga dapat memengaruhi respons imun.
              </p>
              <p className="mb-6" style={{ textAlign: 'justify' }}>
                &emsp;&ensp;Periset CASDI berharap penelitian ini dapat
                memberikan manfaat dalam bidang seputar Autisme dan menjadi
                penelitian yang memampu memantik munculnya penelitian-penelitian
                baru seputar CMV, ASD, dan Imunologi di masa depan.
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default CasdiResearch;
