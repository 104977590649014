import React from 'react'

const GermasifScreen = () => {
    return (
        <>
            <section className="wrapper bg-light">
                <div className="container py-14 py-md-16">
                    <div className="row gx-lg-8 gx-xl-12 gy-10 mb-4 align-items-center">
                        {/* <div className="col-lg-6 position-relative order-lg-2">
                            <div
                                className="shape bg-dot primary rellax w-16 h-20"
                                data-rellax-speed="1"
                                style={{ top: '3rem', left: '5.5rem' }}
                            ></div>
                            <div className="overlap-grid overlap-grid-2">
                                <div className="item">
                                    <figure className="rounded shadow">
                                        <img
                                            src="/assets/img/photos/about2.png"
                                            srcSet="/assets/img/photos/about2.png"
                                            alt=""
                                        ></img>
                                    </figure>
                                </div>
                                <div className="item">
                                    <figure className="rounded shadow">
                                        <img
                                            src="/assets/img/photos/about3.png"
                                            srcSet="/assets/img/photos/about3.png"
                                            alt=""
                                        ></img>
                                    </figure>
                                </div>
                            </div>
                        </div> */}
                        <div className="col-lg-10">
                            <img
                                src="./assets/img/icons/megaphone.svg"
                                className="svg-inject icon-svg icon-svg-md mb-4"
                                alt=""
                            />
                            <h2 className="display-5 mb-3">Gerakan Masyarakat Inklusif (GERMASIF)</h2>
                            <p className="mb-6 text-justify">
                                Stigmatisasi masyarakat terhadap individu autistik dan
                                keluarganya dapat diturunkan dengan cara mengedukasi masyarakat
                                secara komprehensif. Memberikan pemahaman yang berlandaskan ilmu
                                pengetahuan dengan bukti ilmiah dapat meningkatkan kesadaran
                                masyarakat dan menghilangkan rasa takut mereka hidup
                                berdampingan dengan individu autistik. Program ini adalah bagian
                                dari misi yang dilakukan peduli ASD melalui gerakan pengabdian
                                kepada masyarakat yang bertujuan untuk menambah pemahaman
                                masyarakat terhadap ASD dan menurunkan rasa takut
                                keluarga/lembaga pengasuhan ketika anak ASD berpartisipasi dalam
                                lingkungan sosial. 
                            </p>
                            <p className="mb-6 text-justify">
                                Selama ini, kesulitan anak autistik
                                berkomunikasi untuk memberikan informasi seputar dirinya menjadi
                                ancaman bagi keluarga/pengasuh. Apabila lingkungan tidak
                                inklusif, kasus anak autistik hilang tidak akan tertangani
                                dengan baik. Hal ini dikhawatirkan meningkatkan tindakan
                                kriminal yang menjadikan individu autistik ataupun anak
                                berkebutuhan khusus menjadi target oleh para kriminal dalam
                                melakukan aksi kejahatannya. Pada program ini peduli ASD tentu
                                akan bekerjasama dengan banyak pihak untuk dapat bersama-sama
                                mendukung gerakan masyarakat inklusif dan ramah dengan individu
                                ASD. Program ini tidak hanya menyasar masyarakat di sekitar
                                lingkungan tempat tinggal anak ASD, tapi juga kepada orangtua
                                dan keluarga yang bersentuhan langsung dengan mereka guna
                                mendukung peningkatan keterlibatan orang tua dan keluarga dalam
                                penanganan individu ASD yang dapat menjadi salah satu cara untuk
                                meningkatkan kualitas hidup mereka.
                            </p>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default GermasifScreen
