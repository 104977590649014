export const skriningTes = [
    {
        "question": `Jika anda menunjuk sesuatu di ruangan, apakah anak anda melihatnya? (Misalnya,
            jika anda menunjuk hewan atau mainan, apakah anak anda melihat ke arah hewan atau
            mainan yang anda tunjuk?)`,
        "multichoice": [
            {
                "answer": "iya",
                "score": 0
            },
            {
                "answer": "tidak",
                "score": 1
            }
        ]
    },
    {
        "question": `Pernahkah anda berpikir bahwa anak anda tuli?`,
        "multichoice": [
            {
                "answer": "iya",
                "score": 1
            },
            {
                "answer": "tidak",
                "score": 0
            }
        ]
    },
    {
        "question": `Apakah anak anda pernah bermain pura-pura? (Misalnya, berpura-pura minum dari
            gelas kosong, berpura-pura berbicara menggunakan telepon, atau menyuapi boneka
            atau boneka binatang?)`,
        "multichoice": [
            {
                "answer": "iya",
                "score": 0
            },
            {
                "answer": "tidak",
                "score": 1
            }
        ]
    },
    {
        "question": `Apakah anak anda suka memanjat benda-benda? (Misalnya, furniture, alat-alat
            bermain, atau tangga)`,
        "multichoice": [
            {
                "answer": "iya",
                "score": 0
            },
            {
                "answer": "tidak",
                "score": 1
            }
        ]
    },
    {
        "question": `Apakah anak anda menggerakkan jari-jari tangannya dengan cara
        yang tidak biasa di dekat matanya? (Misalnya, apakah anak anda menggoyangkan jari
        dekat pada matanya?)`,
        "multichoice": [
            {
                "answer": "iya",
                "score": 1
            },
            {
                "answer": "tidak",
                "score": 0
            }
        ]
    },
    {
        "question": `Apakah anak anda pernah menunjuk dengan satu jari untuk meminta sesuatu atau
        untuk meminta tolong? (Misalnya, menunjuk makanan atau mainan yang jauh dari
        jangkauannya)`,
        "multichoice": [
            {
                "answer": "iya",
                "score": 0
            },
            {
                "answer": "tidak",
                "score": 1
            }
        ]
    },
    {
        "question": `Apakah anak anda pernah menunjuk dengan satu jari untuk menunjukkan sesuatu yang
        menarik pada anda? (Misalnya, menunjuk pada pesawat di langit atau truk besar di
        jalan)`,
        "multichoice": [
            {
                "answer": "iya",
                "score": 0
            },
            {
                "answer": "tidak",
                "score": 1
            }
        ]
    },
    {
        "question": `Apakah anak anda tertarik pada anak lain? (Misalnya, apakah anak anda
            memperhatikan anak lain, tersenyum pada mereka atau pergi ke arah mereka)`,
        "multichoice": [
            {
                "answer": "iya",
                "score": 0
            },
            {
                "answer": "tidak",
                "score": 1
            }
        ]
    },
    {
        "question": `Apakah anak anda pernah memperlihatkan suatu benda dengan membawa atau
        mengangkatnya kepada anda – tidak untuk minta tolong, hanya untuk berbagi?
        (Misalnya, memperlihatkan anda bunga, binatang atau truk mainan)`,
        "multichoice": [
            {
                "answer": "iya",
                "score": 0
            },
            {
                "answer": "tidak",
                "score": 1
            }
        ]
    },
    {
        "question": `Apakah anak anda memberikan respon jika namanya dipanggil? (Misalnya, apakah
            anak anda melihat, bicara atau bergumam, atau menghentikan apa yang sedang
            dilakukannya saat anda memanggil namanya)`,
        "multichoice": [
            {
                "answer": "iya",
                "score": 0
            },
            {
                "answer": "tidak",
                "score": 1
            }
        ]
    },
    {
        "question": `Saat anda tersenyum pada anak anda, apakah anak anda tersenyum balik?`,
        "multichoice": [
            {
                "answer": "iya",
                "score": 0
            },
            {
                "answer": "tidak",
                "score": 1
            }
        ]
    },
    {
        "question": `Apakah anak anda pernah marah saat mendengar suara bising sehari-hari? (Misalnya,
            apakah anak anda berteriak atau menangis saat mendengar suara bising seperti vacuum
            cleaner atau musik keras)`,
        "multichoice": [
            {
                "answer": "iya",
                "score": 1
            },
            {
                "answer": "tidak",
                "score": 0
            }
        ]
    },
    {
        "question": `Apakah anak anda bisa berjalan?`,
        "multichoice": [
            {
                "answer": "iya",
                "score": 0
            },
            {
                "answer": "tidak",
                "score": 1
            }
        ]
    },
    {
        "question": `Apakah anak anda menatap mata anda saat anda bicara padanya, bermain bersamanya,
        atau saat memakaikan pakaian?`,
        "multichoice": [
            {
                "answer": "iya",
                "score": 0
            },
            {
                "answer": "tidak",
                "score": 1
            }
        ]
    },
    {
        "question": `Apakah anak anda mencoba meniru apa yang anda lakukan? (Misalnya, melambaikan
            tangan, tepuk tangan atau meniru saat anda membuat suara lucu)`,
        "multichoice": [
            {
                "answer": "iya",
                "score": 0
            },
            {
                "answer": "tidak",
                "score": 1
            }
        ]
    },
    {
        "question": `Jika anda memutar kepala untuk melihat sesuatu, apakah anak anda melihat sekeliling
        untuk melihat apa yang anda lihat?`,
        "multichoice": [
            {
                "answer": "iya",
                "score": 0
            },
            {
                "answer": "tidak",
                "score": 1
            }
        ]
    },
    {
        "question": `Apakah anak anda mencoba utuk membuat anda melihat kepadanya? (Misalnya,
            apakah anak anda melihat anda untuk dipuji atau berkata “lihat” atau “lihat aku”)`,
        "multichoice": [
            {
                "answer": "iya",
                "score": 0
            },
            {
                "answer": "tidak",
                "score": 1
            }
        ]
    },
    {
        "question": `Apakah anak anda mengerti saat anda memintanya melakukan sesuatu? (Misalnya,
            jika anda tidak menunjuk, apakah anak anda mengerti kalimat “letakkan buku itu di
            atas kursi” atau “ambilkan saya selimut”)`,
        "multichoice": [
            {
                "answer": "iya",
                "score": 0
            },
            {
                "answer": "tidak",
                "score": 1
            }
        ]
    },
    {
        "question": `Jika sesuatu yang baru terjadi, apakah anak anda menatap wajah anda untuk melihat
        perasaan anda tentang hal tersebut? (Misalnya, jika anak anda mendengar bunyi aneh
        atau lucu, atau melihat mainan baru, akankah dia menatap wajah anda?)`,
        "multichoice": [
            {
                "answer": "iya",
                "score": 0
            },
            {
                "answer": "tidak",
                "score": 1
            }
        ]
    },
    {
        "question": `Apakah anak anda menyukai aktivitas yang bergerak? (Misalnya, diayun-ayun atau
            dihentak-hentakkan pada lutut anda)`,
        "multichoice": [
            {
                "answer": "iya",
                "score": 0
            },
            {
                "answer": "tidak",
                "score": 1
            }
        ]
    },
]