import React from 'react';

const CasdiAbout = () => {
  return (
    <>
      <section className="wrapper bg-light angled upper-end lower-end">
        <div className="container py-14 py-md-16">
          <div className="row gx-lg-8 gx-xl-12 gy-10 align-items-center">
            <div className="col-xl-6 mx-auto">
              <h1 className="display-1 mb-4">Tentang CASDI</h1>
            </div>
            <div className="col-lg-12">
              <img
                src="/assets/img/icons/megaphone.svg"
                className="svg-inject icon-svg icon-svg-md mb-4"
                alt=""
              />
              <p className="mb-6" style={{ textAlign: 'justify' }}>
                &emsp;&ensp;CASDI (
                <i>Cytomegalovirus, Autism Spectrum Disorder, and Immunology</i>
                ) adalah gabungan dari 3 topik utama yang saling berkaitan satu
                sama lain. Topik pertama yaitu <i>Cytomegalovirus</i> (CMV) yang
                dapat menyebabkan infeksi pada manusia. CMV dapat menular
                melalui kontak langsung dengan cairan tubuh yang terinfeksi,
                seperti air liur, air mata, urine, dan darah. Infeksi CMV
                biasanya tidak menimbulkan gejala pada orang yang memiliki
                sistem kekebalan yang sehat, namun dapat menyebabkan masalah
                pada individu dengan sistem kekebalan yang lemah, seperti bayi
                yang masih dalam kandungan, orang dengan HIV/AIDS, atau orang
                yang sedang menjalani transplantasi organ. Dalam beberapa studi,
                CMV telah dikaitkan dengan risiko peningkatan terjadinya{' '}
                <i>Autism Spectrum Disorder</i> (ASD) yang merupakan topik
                kedua. ASD adalah gangguan perkembangan neurologis yang
                mempengaruhi komunikasi sosial, perilaku, dan interaksi sosial
                seseorang. Penelitian menunjukkan bahwa CMV dapat mempengaruhi
                perkembangan otak janin dan sistem kekebalan tubuh, yang
                kemudian dapat berkontribusi terhadap terjadinya ASD.
              </p>
              <p className="mb-6" style={{ textAlign: 'justify' }}>
                &emsp;&ensp;Meskipun CMV dapat berperan dalam perkembangan ASD,
                penting untuk diingat bahwa tidak semua anak dengan ASD memiliki
                infeksi CMV. Faktor genetik dan lingkungan lainnya juga dapat
                berperan dalam terjadinya ASD. Diagnosa ASD harus dilakukan oleh
                profesional medis yang berpengalaman dan didasarkan pada
                observasi perilaku dan evaluasi perkembangan anak secara
                menyeluruh. Sistem kekebalan tubuh (topik ketiga) berperan
                penting dalam melawan infeksi virus, termasuk CMV. Ketika virus
                memasuki tubuh, sistem kekebalan akan merespons dengan
                menghasilkan zat-zat yang disebut antibodi. Antibodi ini
                membantu melawan dan menghancurkan virus, sehingga mencegah
                penyebaran infeksi. Namun, CMV memiliki kemampuan untuk
                menghindari respons kekebalan tubuh. Virus ini dapat menginfeksi
                sel-sel yang membentuk bagian penting dari sistem kekebalan,
                seperti sel darah putih, dan bahkan dapat menyembunyikan dirinya
                dalam sel-sel tubuh yang sehat. Hal ini membuat CMV sulit untuk
                dieliminasi oleh sistem kekebalan.
              </p>
              <p className="mb-6" style={{ textAlign: 'justify' }}>
                &emsp;&ensp;Infeksi CMV yang tidak terkendali dapat menyebabkan
                masalah kesehatan yang serius, terutama pada individu dengan
                sistem kekebalan yang lemah. Oleh karena itu, penting untuk
                menjaga sistem kekebalan tubuh yang sehat dengan cara menjaga
                kebersihan, menghindari kontak dengan cairan tubuh yang
                terinfeksi, dan mengikuti langkah-langkah pencegahan yang
                direkomendasikan oleh tenaga medis. Pemahaman tentang imunologi,
                yaitu ilmu yang mempelajari tentang sistem kekebalan tubuh,
                sangat penting dalam memahami bagaimana CMV dan virus lainnya
                bekerja dalam tubuh manusia. Dengan pemahaman yang lebih baik
                tentang imunologi, peneliti dapat mengembangkan strategi untuk
                melawan infeksi viral, termasuk CMV, dan mencegah terjadinya
                komplikasi yang serius pada individu yang rentan.
              </p>
              <p className="mb-6" style={{ textAlign: 'justify' }}>
                &emsp;&ensp;Sistem kekebalan tubuh juga berperan penting dalam
                merespons adanya gangguan atau perubahan dalam tubuh. Pada
                beberapa individu dengan ASD, sistem kekebalan tubuh dapat
                mengalami ketidakseimbangan atau disfungsi. Ini dapat terjadi
                dalam bentuk peradangan kronis atau respons kekebalan yang
                berlebihan terhadap rangsangan yang seharusnya tidak menyebabkan
                reaksi seperti itu. Beberapa penelitian menunjukkan adanya
                peradangan kronis pada sistem saraf pusat pada individu dengan
                ASD. Ini menunjukkan bahwa sistem kekebalan tubuh mungkin
                terlibat dalam perkembangan gangguan ini. Selain itu, penelitian
                juga menunjukkan adanya antibodi yang berlebihan atau
                autoantibodi pada beberapa individu dengan ASD. Autoantibodi
                adalah antibodi yang menyerang jaringan dan organ tubuh sendiri.
                Keberadaan autoantibodi ini dapat membuktikan bahwa sistem
                kekebalan tubuh berperan dalam ASD.
              </p>
              <p className="mb-6" style={{ textAlign: 'justify' }}>
                &emsp;&ensp;CASDI ditemukan oleh Isti Anindya, S.Si., M.Sc saat
                sedang menyelesaikan Program Doktoral Ilmu Biomedik di Fakultas
                Kedokteran Universitas Indonesia. Founder Peduli ASD ini
                dibimbing oleh Prof. dr. Amin Soebandrio, Sp.MK (K), PhD, Prof.
                Dr. dr. Rini Sekartini, Sp.A (K), dan Dr. Ibnu Agus Ariyanto,
                S.Si., M.Biomed. Pada tahun 2023, CASDI berhasil mendapatkan
                Pendanaan Riset dan Inovasi untuk Indonesia Maju (RIIM) dari
                BRIN dan melakukan riset pertamanya yang berjudul{' '}
                <strong>
                  “Pengembangan Aplikasi Skrining Penanganan ASD (
                  <i>Autism Spectrum Disorder</i>) : Studi Biomarker pada Anak
                  ASD Seropositif CMV (<i>Cytomegalovirus</i>) dan Kaitannya
                  dengan Kondisi Autistik.”
                </strong>{' '}
                Selanjutnya, CASDI berupaya terus membuka kesempatan untuk
                berkolaborasi dalam bidang riset seputar CMV, ASD, dan Imunologi
                bersama periset lainnya pada tahun-tahun berikutnya. Aplikasi
                CASDI ke depannya akan digunakan untuk membantu periset
                mengakomodasi keperluan subyek penelitian secara digital. CASDI
                menjadi bagian dari Peduli ASD dan menjadi tema riset pertama
                yang mengangkat isu ASD di Indonesia. Semoga ke depannya akan
                banyak lagi penelitian-penelitian seputar ASD yang memberikan
                manfaat dan menjaga keberlanjutan untuk memperkaya ilmu
                pengetahuan seputar Autisme.
              </p>
            </div>
            <div className="col-lg-12">
              <img
                src="/assets/img/gambar/logo_casdi.jpeg"
                style={{
                  maxWidth: '50%',
                  height: 'auto',
                  display: 'block',
                  marginLeft: 'auto',
                  marginRight: 'auto',
                }}
                alt=""
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default CasdiAbout;
