export const CREATE_ARTICLE_REQUEST = 'CREATE_ARTICLE_REQUEST'
export const CREATE_ARTICLE_SUCCESS = 'CREATE_ARTICLE_SUCCESS'
export const CREATE_ARTICLE_FAIL = 'CREATE_ARTICLE_FAIL'
export const CREATE_ARTICLE_REMOVE = 'CREATE_ARTICLE_REMOVE'

export const LIST_ARTICLE_REQUEST = 'LIST_ARTICLE_REQUEST'
export const LIST_ARTICLE_SUCCESS = 'LIST_ARTICLE_SUCCESS'
export const LIST_ARTICLE_FAIL = 'LIST_ARTICLE_FAIL'

export const ARTICLE_BY_ID_REQUEST = 'ARTICLE_BY_ID_REQUEST'
export const ARTICLE_BY_ID_SUCCESS = 'ARTICLE_BY_ID_SUCCESS'
export const ARTICLE_BY_ID_FAIL = 'ARTICLE_BY_ID_FAIL'

export const UPDATE_ARTICLE_REQUEST = 'UPDATE_ARTICLE_REQUEST'
export const UPDATE_ARTICLE_SUCCESS = 'UPDATE_ARTICLE_SUCCESS'
export const UPDATE_ARTICLE_FAIL = 'UPDATE_ARTICLE_FAIL'

export const DELETE_ARTICLE_REQUEST = 'DELETE_ARTICLE_REQUEST'
export const DELETE_ARTICLE_SUCCESS = 'DELETE_ARTICLE_SUCCESS'
export const DELETE_ARTICLE_FAIL = 'DELETE_ARTICLE_FAIL'