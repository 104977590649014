export const USER_LIST_REQUEST = 'USER_LIST_REQUEST'
export const USER_LIST_SUCCESS = 'USER_LIST_SUCCESS'
export const USER_LIST_FAIL = 'USER_LIST_FAIL'
export const USER_LIST_REMOVE = 'USER_LIST_REMOVE'

export const USER_BY_ID_REQUEST = 'USER_BY_ID_REQUEST'
export const USER_BY_ID_SUCCESS = 'USER_BY_ID_SUCCESS'
export const USER_BY_ID_FAIL = 'USER_BY_ID_FAIL'
export const USER_BY_ID_REMOVE = 'USER_BY_ID_REMOVE'

export const UPDATE_USER_REQUEST = 'UPDATE_USER_REQUEST'
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS'
export const UPDATE_USER_FAIL = 'UPDATE_USER_FAIL'