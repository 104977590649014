export const CREATE_PRODUCT_REQUEST = 'CREATE_PRODUCT_REQUEST'
export const CREATE_PRODUCT_SUCCESS = 'CREATE_PRODUCT_SUCCESS'
export const CREATE_PRODUCT_FAIL = 'CREATE_PRODUCT_FAIL'
export const CREATE_PRODUCT_REMOVE = 'CREATE_PRODUCT_REMOVE'

export const LIST_PRODUCT_REQUEST = 'LIST_PRODUCT_REQUEST'
export const LIST_PRODUCT_SUCCESS = 'LIST_PRODUCT_SUCCESS'
export const LIST_PRODUCT_FAIL = 'LIST_PRODUCT_FAIL'

export const PRODUCT_BY_ID_REQUEST = 'PRODUCT_BY_ID_REQUEST'
export const PRODUCT_BY_ID_SUCCESS = 'PRODUCT_BY_ID_SUCCESS'
export const PRODUCT_BY_ID_FAIL = 'PRODUCT_BY_ID_FAIL'
