export const CREATE_EDUPROGRAM_REQUEST = 'CREATE_EDUPROGRAM_REQUEST'
export const CREATE_EDUPROGRAM_SUCCESS = 'CREATE_EDUPROGRAM_SUCCESS'
export const CREATE_EDUPROGRAM_FAIL = 'CREATE_EDUPROGRAM_FAIL'
export const CREATE_EDUPROGRAM_REMOVE = 'CREATE_EDUPROGRAM_REMOVE'

export const LIST_EDUPROGRAM_REQUEST = 'LIST_EDUPROGRAM_REQUEST'
export const LIST_EDUPROGRAM_SUCCESS = 'LIST_EDUPROGRAM_SUCCESS'
export const LIST_EDUPROGRAM_FAIL = 'LIST_EDUPROGRAM_FAIL'

export const GET_BY_ID_EDUPROGRAM_REQUEST = 'GET_BY_ID_EDUPROGRAM_REQUEST'
export const GET_BY_ID_EDUPROGRAM_SUCCESS = 'GET_BY_ID_EDUPROGRAM_SUCCESS'
export const GET_BY_ID_EDUPROGRAM_FAIL = 'GET_BY_ID_EDUPROGRAM_FAIL'

export const DELETE_EDUPROGRAM_REQUEST = 'DELETE_EDUPROGRAM_REQUEST'
export const DELETE_EDUPROGRAM_SUCCESS = 'DELETE_EDUPROGRAM_SUCCESS'
export const DELETE_EDUPROGRAM_FAIL = 'DELETE_EDUPROGRAM_FAIL'